import React, {useEffect, useRef} from 'react'
import {useDragAndDropFile} from '../../../hooks/useDragAndDropFile'
import useUploadFile from '../../../hooks/useUploadFile'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionCopyLayer,
    actionPasteLayer,
    actionRedo,
    actionSetRunningImageCompress,
    actionUndo,
    actionUpdateSizeEditorWrapper,
    removeLayer,
} from '../context/SelectedVariantContext/action'
import {hideToolbar} from '../context/SelectedVariantContext/helper'
import {useEditorSides} from '../../../hooks/useEditorSides'
import {useEditorBackground} from '../../../hooks/useEditorBackground'

const EditorWrapper = ({children, classes, ...rest}) => {
    const editorWrapperRef = useRef(null)
    const {onDragOver, onDrop} = useDragAndDropFile()
    const {uploadFile} = useUploadFile()

    /**
     * @type {State} state
     */
    const {
        state: {selectedLayer, stageDrag, loadingImageLayer},
        dispatch,
    } = useSelectedVariantContext()

    const {ratioDefault} = useEditorBackground()
    const {isOptionalSide} = useEditorSides()

    useEffect(() => {
        let resizeTimer = null
        const handleInitialResize = () => {
            if (resizeTimer) clearTimeout(resizeTimer)
            resizeTimer = setTimeout(() => {
                if (editorWrapperRef.current) {
                    let {offsetHeight} = editorWrapperRef.current
                    dispatch(actionUpdateSizeEditorWrapper({width: 1000, height: offsetHeight}))
                }
            }, 200)
        }
        handleInitialResize()
        window.addEventListener('resize', handleInitialResize)
        return () => {
            window.removeEventListener('resize', handleInitialResize)
        }
    }, [dispatch])

    const handleDrop = (e) => {
        if (isOptionalSide) return
        dispatch(actionSetRunningImageCompress(true))
        const file = onDrop(e)
        return uploadFile(file)
    }

    useEffect(() => {
        if (!selectedLayer) {
            hideToolbar()
        }
    }, [selectedLayer])

    // keyboard events
    useEffect(() => {
        const handleKeyDown = (event) => {
            const isMacBook = /Mac/.test(navigator.platform)
            const {key, keyCode, ctrlKey, metaKey} = event || {}

            /* delete */
            if (selectedLayer && (keyCode === 46 || key === 'Delete')) {
                if (selectedLayer.isLock) return
                dispatch(removeLayer(selectedLayer))
                hideToolbar()
            }
            if ((ctrlKey && event.key === 'z') || (isMacBook && key === 'z' && (metaKey || ctrlKey))) {
                hideToolbar()
                dispatch(actionUndo())
            } else if ((ctrlKey && key === 'y') || (isMacBook && key === 'y' && (metaKey || ctrlKey))) {
                hideToolbar()
                dispatch(actionRedo())
            } else if ((ctrlKey && key === 'c') || (isMacBook && key === 'c' && (metaKey || ctrlKey))) {
                dispatch(actionCopyLayer(selectedLayer))
            } else if (
                !loadingImageLayer &&
                ((ctrlKey && key === 'v') || (isMacBook && key === 'v' && (metaKey || ctrlKey)))
            ) {
                dispatch(actionPasteLayer())
            } else if (ctrlKey && (key === '+' || key === '-' || key === '=')) {
                event.preventDefault()
            }
        }

        const handleWheel = (event) => {
            if (event.ctrlKey) {
                event.preventDefault()
            }
        }

        const handleResize = (event) => {
            event.preventDefault()
        }

        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('resize', handleResize)
        window.addEventListener('wheel', handleWheel, {passive: false})
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('wheel', handleWheel)
        }
    }, [dispatch, selectedLayer, ratioDefault, loadingImageLayer])

    return (
        <div
            {...rest}
            className={classes}
            ref={editorWrapperRef}
            style={{
                cursor: stageDrag ? 'grab' : 'default',
            }}
            onDragOver={onDragOver}
            onDrop={handleDrop}
        >
            {children}
        </div>
    )
}

export default EditorWrapper

