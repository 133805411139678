import Bluebird from 'bluebird'

const cutDesignParts = async (designImage, templateLayer, templateConfigs, cuttingSideConfigs, scale) => {
    // resize all to cut file scale
    return await Bluebird.map(cuttingSideConfigs, async (partConfig) => {
        const partWidthScaled = partConfig.width * scale
        const partHeightScaled = partConfig.height * scale

        const canvas = new OffscreenCanvas(partWidthScaled, partHeightScaled)
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            designImage,
            (templateConfigs.position.left - partConfig.x) * scale,
            (templateConfigs.position.top - partConfig.y) * scale,
            templateLayer.width * scale,
            templateLayer.height * scale,
        )
        ctx.drawImage(
            partConfig.image,
            0,
            0,
            partWidthScaled,
            partHeightScaled,
        )

        return {
            image: canvas,
            width: partWidthScaled,
            height: partHeightScaled,
        };
    }, {concurrency: 3})
}

export default cutDesignParts
