import Bluebird from 'bluebird'
import {useCallback, useState} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {
    actionChangeDisplay,
    actionSaveCacheCapture,
    actionUpdatePreviewImages
} from '../components/step2/context/SelectedVariantContext/action'
import {
    generateImagePreviewUrl,
    handleCaptures,
    handleGenerateDesign,
    handleUploadDesign,
    mergeAttributesByDesign
} from '../components/step2/context/SelectedVariantContext/helper'
import {getLinksUploadArtwork} from '../services/ArtworkTemplateServices'
import {toaster} from '../shared/PFToast'

export function usePreviewArtworks() {
    const {
        state: {pickerAttributes, selectedAttribute, designs, reloadPreview},
        dispatch
    } = useSelectedVariantContext()

    const [pending, setPending] = useState(false)
    const [percentage, setPercentage] = useState(0)

    const fetchAreas = useCallback(
        async (options = {}) => {
            setPending(true)
            const {fetchDesign} = options

            const captures = await handleCaptures(
                selectedAttribute,
                fetchDesign
                    ? {isSafeZone: true}
                    : {isArea: true, scalePreviewRatio: selectedAttribute.scalePreviewRatio}
            ).then((res) => {
                return res
            })
            const artworks = captures.map((capture) => capture.area)

            const result = {mockup: selectedAttribute.mockup, artworks}

            if (fetchDesign) {
                const {design, config} = await handleGenerateDesign(selectedAttribute.mockup, artworks, {
                    scale: selectedAttribute.scalePreviewRatio
                })
                result.design = design
                result.config = config
            }

            return result
        },
        [selectedAttribute]
    )

    const fetchPreviews = useCallback(async () => {
        try {
            console.time('[fetch preview]')
            if (reloadPreview) {
                setPercentage(0)
                setPending(true)
                const startTime = performance.now()
                const intervalId = setInterval(() => {
                    const elapsedTime = performance.now() - startTime
                    const progress = Math.round((elapsedTime / 30000) * 100) // Assuming the promise will take 30 seconds
                    setPercentage(progress)

                    if (progress >= 100) {
                        clearInterval(intervalId)
                    }
                }, 100)

                const attrsData = mergeAttributesByDesign(pickerAttributes, designs)

                const fetchedPreviews = await Bluebird.mapSeries(attrsData, async (attributes) => {
                    const attribute = attributes[0]


                    const captures = await handleCaptures(attribute, {
                        isPreview: true,
                        scalePreviewRatio: attribute.scalePreviewRatio
                    }).then((res) => res)
                    const artworks = captures.map((capture) => capture.artwork)

                    dispatch(
                        actionSaveCacheCapture({
                            attributes,
                            artworks: artworks,
                            type: 'preview'
                        })
                    )




                    const {design, config} = await handleGenerateDesign(attribute.mockup, artworks, {
                        ratio: attribute.scalePreviewRatio
                    })

                    const arrAttributes = []
                    const listSku = []
                    for (const item of attributes) {
                        arrAttributes.push(item.attributes.map((attr) => ({type: attr.type, value: attr.value_code})))
                        listSku.push(...item.listSku)
                    }

                    return {
                        attributes: arrAttributes,
                        attribute,
                        listSku,
                        design,
                        config
                    }
                })
                
                const totalPreview = fetchedPreviews.length
                const {data} = await getLinksUploadArtwork({limit: totalPreview})

                const presignedUrls = data || []
                if (presignedUrls.length !== totalPreview) throw new Error('Failed to get presigned url')

                const previewImages = await Bluebird.map(fetchedPreviews, async (preview, index) => {
                    const {attributes, listSku, design, config, attribute} = preview
                    const presignedUrl = presignedUrls[index]
                    const payload = {
                        design,
                        config,
                        presignedUrl
                    }
                    const {data, success, message} = await handleUploadDesign(payload)
                    if (!success) throw new Error(message)

                    const images = generateImagePreviewUrl(data, attribute)

                    return {
                        images,
                        attributes,
                        listSku
                    }
                })

                clearInterval(intervalId)
                setPercentage(100)
                setTimeout(() => {
                    setPending(false)
                    dispatch(actionUpdatePreviewImages(previewImages))
                }, 300)
            }
        } catch (error) {
            toaster({
                type: 'error',
                message: `Overload: ${error.message}`
            })
            setPending(false)
            dispatch(actionChangeDisplay('design'))
        } finally {
            console.timeEnd('[fetch preview]')
        }
    }, [pickerAttributes, dispatch, designs, reloadPreview])

    const fetchDesigns = useCallback(async () => {
        try {
            setPending(true)

            const attrsData = mergeAttributesByDesign(pickerAttributes, designs)

            const fetchedDesigns = await Bluebird.mapSeries(attrsData, async (attributes) => {
                const attribute = attributes[0]
                const captures = await handleCaptures(attribute, {isPublish: true}).then((res) => res)
                const artworks = captures.map((capture) => capture.artwork)

                dispatch(
                    actionSaveCacheCapture({
                        attributes,
                        artworks: artworks,
                        type: 'publish'
                    })
                )

                const {design} = await handleGenerateDesign(attribute.mockup, artworks)

                const arrAttributes = []
                const listSku = []

                for (const item of attributes) {
                    arrAttributes.push(item.attributes.map((attr) => ({type: attr.type, value: attr.value_code})))
                    listSku.push(...item.listSku)
                }

                return {
                    design,
                    blob: URL.createObjectURL(design),
                    mockup: attribute.mockup,
                    listSku,
                    level: attribute.level || 'variant'
                }
            })
            
            return fetchedDesigns
        } catch (error) {
            toaster({
                type: 'error',
                message: `Overload: ${error.message}`
            })
        } finally {
            setPending(false)
        }
    }, [pickerAttributes, designs, dispatch])

    const fetchPreview3D = useCallback(async (options = {}) => {
        try {
            const attrsData = mergeAttributesByDesign(pickerAttributes, designs)
            console.log({attrsData})
            const fetchedPreviews = await Bluebird.mapSeries(attrsData, async (attributes) => {
                const attribute = attributes[0]

                const captures = await handleCaptures(attribute, {
                    isPreview: true,
                    scalePreviewRatio: attribute.scalePreviewRatio
                }).then((res) => res)
                const artworks = captures.map((capture) => capture.artwork)

                dispatch(
                    actionSaveCacheCapture({
                        attributes,
                        artworks: artworks,
                        type: 'preview'
                    })
                )
                
                return {artworks}
            })
                
            return fetchedPreviews         
        } catch (error) {

        } finally {

        }
    }, [pickerAttributes, dispatch, designs, reloadPreview])

    return {fetchAreas, fetchDesigns, percentage, pending, fetchPreviews, fetchPreview3D}
}

