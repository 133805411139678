import Bluebird from 'bluebird'
import { generatePersonalizedArtworkV4 } from '../services/MockupGeneratorServices'
import { activateProductPOD, createProductPODV4, deleteProduct, uploadImageGallery } from '../services/ProductServices'
import { searchVariants } from '../services/VariantServices'

export const getInfomationBackground = (type, currentBgImage, wPreview = 600, hPreview = 600) => {
    let marginTop = (hPreview-600) / 2
    let marginLeft = (wPreview-600) / 2
    let width = 600
    let height = 600
    let x = 0
    let y = 0
    if (type === 'fit' || type === 'original') {
        if (currentBgImage.naturalWidth < 600 && currentBgImage.naturalHeight < 600) {
            width = currentBgImage.naturalWidth
            height = currentBgImage.naturalHeight
            x = 300 - width / 2
            y = 300 - height / 2
        } else if (currentBgImage.naturalWidth > 600 && currentBgImage.naturalHeight < 600) {
            height = currentBgImage.naturalHeight / (currentBgImage.naturalWidth / 600)
            y = 300 - height / 2
        } else if (currentBgImage.naturalWidth < 600 && currentBgImage.naturalHeight > 600) {
            width = currentBgImage.naturalWidth / (currentBgImage.naturalHeight / 600)
            x = 300 - width / 2
        } else {
            if (currentBgImage.naturalWidth > currentBgImage.naturalHeight) {
                height = currentBgImage.naturalHeight / (currentBgImage.naturalWidth / 600)
                y = 300 - height / 2
            } else if (currentBgImage.naturalWidth < currentBgImage.naturalHeight) {
                width = currentBgImage.naturalWidth / (currentBgImage.naturalHeight / 600)
                x = 300 - width / 2
            }
        }
    }
    return {
        width, height, x: x + marginLeft, y: y + marginTop
    }
}

export const createProductCombine = async (params) => {
    const {product, personalizedArtworkPayload, variants, galleryImage, setCampaign, currentBgImage} = params
    let productIDCreated = ''

    try {
        console.time('create product')
        const {attributes, collections} = product

        const filteredAttributes = attributes.map((attribute) => {
            const filteredValues = attribute.values.filter((value) =>
                variants.some((variant) =>
                    variant.attributes.some(
                        (variantAttribute) =>
                            variantAttribute.name === attribute.name && variantAttribute.value_code === value.code
                    )
                )
            )

            return {...attribute, values: filteredValues}
        })

        const payload1 = {
            ...product,
            collections: Array.isArray(collections) ? collections.map((collection) => collection.value) : [],
            productLine: {
                variants,
                attributes: filteredAttributes,
            }
        }
        delete payload1.mockups

        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            status: 'Uploading',
        }))

        // 1. create a new product
        const resCreateProduct = await createProductPODV4(payload1)
        if (!resCreateProduct.success) throw new Error(resCreateProduct.message)

        productIDCreated = resCreateProduct.data._id

        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            url: resCreateProduct.data.url,
            productId: productIDCreated,
        }))

        // 2. upload artwork personalize for product
        await Bluebird.map(
            personalizedArtworkPayload,
            async (payload) => {
                const formData = new FormData()
                formData.append('product_id', productIDCreated)

                for (const [key, value] of Object.entries(payload)) {
                    formData.append(key, value)
                }

                const resGenPersonalize = await generatePersonalizedArtworkV4(formData)
                if (!resGenPersonalize.success) throw new Error(resGenPersonalize.message)
            },
            {concurrency: 2}
        )

        // 3. upload gallery for product
        if (galleryImage) {
            const resUploadGallery = await uploadImageGallery(productIDCreated, {galleryUrls: [galleryImage.replace('regular.jpg', `background:${currentBgImage?.file ? encodeURIComponent(encodeURIComponent(personalizedArtworkPayload[0].keyBackground)) : 'default'}/regular.jpg`)]})
            if (!resUploadGallery.success) throw new Error(resUploadGallery.message)
        }

        // 4. activate product
        const resActiveProduct = await activateProductPOD(productIDCreated)
        if (!resActiveProduct.success) throw new Error(resActiveProduct.message)

        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            status: 'Completed',
        }))

        // 5. Request get variant images
        const resSearchVariants = await searchVariants(productIDCreated, [], {limit: 10000})
        if (resSearchVariants?.success) {
            const images = resSearchVariants.data.variants.reduce((accumulator, variant) => {
                const {image_uris = []} = variant
                return accumulator.concat(image_uris)
            }, [])

            setCampaign((prevCampaigns) => ({
                ...prevCampaigns,
                mockup: {
                    total: images.length,
                    images,
                },
            }))
        }
        return true
    } catch (error) {
        deleteProduct(productIDCreated)
        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            status: 'Error',
            error: error.message,
        }))
        throw new Error(error)
    } finally {
        console.timeEnd('create product')
    }
}

