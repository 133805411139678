import classNames from 'classnames'
import React, {Fragment, memo, useCallback, useEffect, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {Button, Input, Spinner} from 'reactstrap'
import {ReactComponent as IconCheck} from '../../../../assets/icons/check.svg'
import {ReactComponent as Trash} from '../../../../assets/icons/trash-alt.svg'
import EmptyList from '../../../../assets/images/empty-list.png'
import {
    deleteDesignLibraryImage,
    getDesignLibraryImage,
    getListDesignLibrary,
} from '../../../../services/ArtworkTemplateServices'
import PFButton from '../../../../shared/PFButton'
import {confirmModal} from '../../../../shared/PFConfirmModal'
import {toaster} from '../../../../shared/PFToast'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {actionAddImageLayer, actionChangeTabDesign} from '../../context/SelectedVariantContext/action'
import {isBackofficeEnv as checkEnv} from '../../../../helpers/isBackoffceEnv'
import {getLocalData, setLocalData} from '../../../../services/StorageServices'
import {ReactComponent as Transparent} from '../../../../assets/icons/transparent.svg'
import randomstring from 'randomstring'

const UploadByAssets = (props) => {
    const {dispatch} = useSelectedVariantContext()
    const {toggle} = props
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [listAssets, setListAssets] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [params, setParams] = useState({
        name: '',
        sort_key: 'created_at',
        sort_order: 'desc',
        page: 1,
        limit: 20,
    })
    const [pages, setPages] = useState(0)
    let _timeOut = null
    const designLibrary = getLocalData('designLibrary') || []

    const getWindowWidth = () => {
        const {innerWidth: width} = window
        return width
    }

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())
    const isBackofficeEnv = checkEnv()

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const _fetchDesignLibrary = useCallback(async () => {
        try {
            setLoadingFetch(true)
            if (isBackofficeEnv) {
                const {success, data, message} = await getListDesignLibrary(params)
                if (!success) throw new Error(message)
                const {images, pages, total} = data
                setTotal(total)
                setPages(pages)
                setHasMore(params.page < pages)
                setListAssets(images)
            } else {
                setHasMore(false)
                const filteredDesign = designLibrary.filter((obj) => obj.name.includes(params.name))
                filteredDesign.sort((a, b) => {
                    if (params.sort_order === 'asc') {
                        return a.name.localeCompare(b.name)
                    }
                    return b.name.localeCompare(a.name)
                })
                setListAssets(filteredDesign)
            }
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoadingFetch(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.name, params.sort_order, params.sort_order])

    useEffect(() => {
        _fetchDesignLibrary()
    }, [_fetchDesignLibrary])

    const _next = async () => {
        const newPage = params.page + 1
        try {
            if (newPage > pages) return setHasMore(false)
            const {success, data, message} = await getListDesignLibrary({...params, page: newPage})
            if (!success) throw new Error(message)
            const {images, pages: responsePages} = data
            setPages(responsePages)
            const concatAssets = [...listAssets, ...images]
            setListAssets(concatAssets)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setParams((prev) => ({...prev, page: newPage}))
        }
    }

    const _fetchRegularDesignImage = async (imageId) => {
        try {
            const {data, success, message} = await getDesignLibraryImage(imageId)
            if (!success) throw new Error(message)
            return data
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const _handleSearch = (event) => {
        const {name, value} = event.target
        if (_timeOut) clearTimeout(_timeOut)

        _timeOut = setTimeout(() => {
            setParams((prev) => ({...prev, [name]: value ? value.trim() : '', page: 1}))
        }, 500)
    }

    const _handleDeleteImage = (imageId) => {
        const _deleteImage = async () => {
            try {
                if (isBackofficeEnv) {
                    const {success, message} = await deleteDesignLibraryImage(imageId)
                    if (!success) throw new Error(message)
                    _fetchDesignLibrary()
                } else {
                    const _listAssets = Object.assign([], listAssets)
                    if (_listAssets.length > 0) {
                        const availableDesigns = _listAssets.filter((design) => {
                            return design._id !== imageId
                        })
                        setLocalData('designLibrary', availableDesigns)
                        setListAssets(availableDesigns)
                    }
                }
            } catch (error) {
                toaster({
                    type: 'error',
                    message: error.message,
                })
            }
        }

        confirmModal({
            confirmText: 'Delete',
            confirmColor: 'danger',
            title: 'Confirm Delete image',
            message: 'Are you sure you want to delete this image?',
            onOk: () => _deleteImage(),
        })
    }

    const _addImage = async (selectedImage) => {
        try {
            setLoading(true)
            let imageObj = null
            if (isBackofficeEnv) {
                const regularImage = await _fetchRegularDesignImage(selectedImage)
                const {origin_url, thumb_url, name, size, mimetype, width, height} = regularImage
                imageObj = {
                    name,
                    _id: randomstring.generate(7),
                    src: origin_url,
                    tinySrc: thumb_url,
                    naturalSize: size,
                    imageType: mimetype,
                    naturalWidth: width,
                    naturalHeight: height,
                    created: new Date(),
                }
            } else {
                imageObj = listAssets.find((asset) => asset._id === selectedImage)
            }
            dispatch(actionAddImageLayer(imageObj))
            setLoading(false)
            toggle()
            dispatch(actionChangeTabDesign('layer'))
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="UploadByAssets">
            <div className="DesignAsset">
                <div className='Filter'>
                    <label>Files in Library ({total})</label>
                    <div className='d-flex align-items-center'>
                        <div className='InputSearch'>
                            <Input name="name" id='inputSearch' placeholder='Search' onChange={_handleSearch} />
                        </div>
                        <div className='SortFilter d-flex align-items-center'>
                            <label>Sort by:</label>
                            <Input
                                name='sort_order'
                                type='select'
                                onChange={_handleSearch}
                            >
                                <option value='desc'>
                                    latest - oldest
                                </option>
                                <option value='asc'>
                                    oldest - latest
                                </option>
                            </Input>
                        </div>
                    </div>
                </div>

                {loadingFetch ? (
                    <div className='ListAssets mt-3'>
                        <Loading />
                    </div>
                ) : (
                    <Fragment>
                        {listAssets.length > 0 ? (
                            <Fragment>
                                {isBackofficeEnv ? (
                                    <InfiniteScroll
                                        next={_next}
                                        hasMore={hasMore}
                                        loader={!loadingFetch && <Loading />}
                                        className='ListAssets mb-3'
                                        dataLength={listAssets.length}
                                        height={windowWidth < 1600 ? 400 : 500}
                                    >
                                        {listAssets.map((asset) => {
                                            const isSelected = selectedImage === asset._id
                                            return (
                                                <AssetItem
                                                    asset={asset}
                                                    isSelected={isSelected}
                                                    isBackofficeEnv={isBackofficeEnv}
                                                    addImage={_addImage}
                                                    setSelectedImage={setSelectedImage}
                                                    handleDeleteImage={_handleDeleteImage}
                                                />
                                            )
                                        })}
                                    </InfiniteScroll>
                                ) : (
                                    <div className="ListAssets mb-3">
                                        {listAssets.map((asset) => {
                                            const isSelected = selectedImage === asset._id
                                            return (
                                                <AssetItem
                                                    asset={asset}
                                                    isSelected={isSelected}
                                                    addImage={_addImage}
                                                    isBackofficeEnv={isBackofficeEnv}
                                                    setSelectedImage={setSelectedImage}
                                                    handleDeleteImage={_handleDeleteImage}
                                                />
                                            )
                                        })}
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <div className="NoImage">
                                <div className="text-center">
                                    <img src={EmptyList} alt="Empty" />
                                    <p>Could not found any image!</p>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            <div className="footerPopup d-flex justify-content-end">
                <Button color="default" onClick={toggle} disabled={loading}>
                    Cancel
                </Button>
                <PFButton
                    color="primary"
                    buttonText="Add"
                    loading={loading}
                    onClick={() => _addImage(selectedImage)}
                    disabled={loading || !selectedImage}
                />
            </div>
        </div>
    )
}

const AssetItem = memo((props) => {
    const {isBackofficeEnv, asset, isSelected, setSelectedImage, addImage, handleDeleteImage} = props

    const {_id, src, thumb_url, name, width, height, size} = asset

    return (
        <Fragment>
            <div
                key={_id}
                className={classNames('AssetItem', {Active: isSelected})}
                onClick={() => setSelectedImage(_id)}
            >
                <div className='position-relative overflow-hidden'>
                    <Transparent width={300} height={300}/>
                    <img className="Thumbnail" src={isBackofficeEnv ? thumb_url : src} alt='thumbnail'
                         onDoubleClick={() => addImage(_id)} />
                </div>
                <div className='Info'>
                    <p className='Title'>{name}</p>
                    <p>Size: {(size / 1024 / 1024).toFixed(2)} Mb</p>
                    <p>Resolution: {width} x {height}</p>
                </div>
                {isSelected && (
                    <div className='Mask'>
                        <span className="Icon">
                            <IconCheck width={12} height={8} />
                        </span>
                    </div>
                )}
                {!isSelected && (
                    <div
                        className="ButtonDelete cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleDeleteImage(_id)
                        }}
                    >
                        <Trash widths={20} height={20} color="#ffffff" />
                    </div>
                )}
            </div>
        </Fragment>
    )
})

const Loading = () => {
    return (
        <div className="LoadingScroll">
            <Spinner style={{color: '#007bff'}} />
        </div>
    )
}

export default UploadByAssets

