import React, {Component} from 'react'
import PropTypes from 'prop-types'
import '../styles/FontSelector.scss'

class FontSelector extends Component {
    _isMounted = false
    fontPicker = null
    dom

    componentDidMount() {
        this._isMounted = true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this._isMounted &&
            !!Object.entries(this.props.itemData).length &&
            (prevProps.itemId !== this.props.itemId || prevProps.fonts !== this.props.fonts)
        ) {
            this.initializeFontPickerLibrary()
        }

        if (this._isMounted) {

            this.applyFontToInput()
        }

        if (this.fontPicker) {
            this.props.disabled && this.fontPicker[0].fontPicker.disable()

            const $ = window.$,
                dom = $(`#${this.props.itemId}`)

            if (this.props.itemData) {
                const incomingValue = this.generateFontMetadataString(this.props.itemData)
                if (this.props.itemData.reset && dom.val() !== incomingValue) {
                    this.fontPicker[0].fontPicker.forceUpdate(incomingValue)
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
        this.fontPicker && this.fontPicker.off('change')
    }

    changeHandler(value) {
        this.props.changeHandler(value)
    }

    generateFontMetadataString(itemData) {
        const {fontFamily, fontWeight, fontStyle, fontType} = itemData
        return itemData.fontFamily
            ? `${fontFamily}:${fontWeight || '400'}${fontStyle ? 'i' : ''}:${fontType || 'local'}`
            : ''
    }

    applyFontToInput() {
        const incomingValue = this.generateFontMetadataString(this.props.itemData)
        this.fontPicker && this.fontPicker[0].fontPicker.applyFontToOriginalInput(incomingValue)
    }

    initializeFontPickerLibrary() {
        const {itemId, fonts, itemData, disabled} = this.props,
            $ = window.$
        const {customFonts, googleFonts} = fonts
        const options = {}

        this.dom = $(`#${itemId}`)

        options.googleFonts = googleFonts
        options.customFonts = customFonts
        options.originalId = itemId
        options.disabled = disabled

        options.oldValue = this.generateFontMetadataString(itemData)

        if (!this.fontPicker) {
            const changeHandlerMethod = (value) => this.changeHandler(value)
            this.fontPicker = this.dom.fontpicker(options)
            this.fontPicker.on('change', function () {
                changeHandlerMethod(this.value)
            })
        }
        this.fontPicker && this.fontPicker[0].fontPicker.updateCustomFont(customFonts)
    }

    render() {
        const {itemId} = this.props
        return <input className="form-control" id={itemId} type="text" />
    }
}

FontSelector.defaultProps = {
    googleFonts: {},
    itemData: '',
    disabled: false,
}

FontSelector.propTypes = {
    itemId: PropTypes.string.isRequired,
    fonts: PropTypes.object,
    itemData: PropTypes.any,
    changeHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

export default FontSelector

