import React from 'react'
import {ReactComponent as ChevronRight} from '../../assets/icons/chevron-right.svg'
import {ReactComponent as ArrowBack} from '../../assets/icons/arrow_back.svg'
import {useSelectedVariantContext} from './context/SelectedVariantContext'
import {actionChangeDisplay, actionChangeSelectedLayer} from './context/SelectedVariantContext/action'
import {toaster} from '../../shared/PFToast'
import {isBackofficeEnv} from '../../helpers/isBackoffceEnv'
import Publish from './publish/Publish'

const NavBar = () => {
    const {
        state: {display, pickerAttributes, productDetail, runningImageCompress},
        dispatch,
    } = useSelectedVariantContext()

    const isDesignStep = display === 'design'
    const isPreviewStep = display === 'preview'
    const isPricingStep = display === 'pricing'

    const toggleDisplay = (_display) => {
        if (_display !== display) {
            dispatch(actionChangeDisplay(_display))
        }
    }

    const switchPreview = () => {
        let invalidDpi = null
        for (const attr of pickerAttributes) {
            const {safeZone} = attr

            for (const [key, value] of Object.entries(safeZone)) {
                const totalInvalidDpi = value.invalidsDpi.length
                if (totalInvalidDpi) {
                    invalidDpi = {
                        side: key,
                        total: totalInvalidDpi,
                    }
                    break
                }
            }
        }
        if (invalidDpi) {
            toaster({
                type: 'error',
                message: `Side ${invalidDpi.side} has ${invalidDpi.total} layer image invalid DPI`,
            })
        } else {
            dispatch(actionChangeSelectedLayer(null))
            toggleDisplay('preview')
        }
    }

    const backToProductList = () => {
        const confirmed = window.confirm('Changes you made may not be saved.')
        if (confirmed) {
            if (isBackofficeEnv()) {
                window.open(`${process.env.REACT_APP_BASE_URL}/a/products/mockup-generator/variants`, '_self')
            } else {
                window.open(`${process.env.REACT_APP_WEBSITE_URL}/product/${productDetail.slug}`, '_self')
            }
        }
    }

    const handleClickNextBtn = () => {
        switchPreview()
    }

    const handleNavigate = () => {
        return isPreviewStep || isPricingStep ? toggleDisplay('design') : backToProductList()
    }

    return (
        <div className="NavBar2 no-gutters">
            <div className="Left col-6 text-left d-flex align-items-center">
                <span className="PrevStep cursor-pointer flex-center" onClick={handleNavigate}>
                    <span className="Icon">
                        <ArrowBack />
                    </span>
                    <span className={'text-primary'}>{isPreviewStep || isPricingStep ? 'Design' : 'Product'}</span>
                </span>
                <div className={'vr mx-3'} />
                <h6 className={'fw-bold mb-0'}>{productDetail.title}</h6>
            </div>

            <div className="Right col-3">
                <div className={'d-flex justify-content-end'}>
                    {isDesignStep ? (
                        <button
                            type={'button'}
                            className={'Design btn btn-primary flex-center'}
                            disabled={runningImageCompress}
                            onClick={handleClickNextBtn}
                        >
                            Next <ChevronRight className={'ms-1'} />
                        </button>
                    ) : (
                        <Publish />
                    )}
                </div>
            </div>
        </div>
    )
}

export default NavBar
