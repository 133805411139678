import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Spinner} from 'reactstrap'
import {isBackofficeEnv} from '../../helpers/isBackoffceEnv'
import {getEnvironment, hasRole} from '../../services/AuthServices'
import {getCurrentStore} from '../../services/CurrentStoreServices'
import {getProductCatalogById, getWebsiteProductCatalogById} from '../../services/ProductLinesServices'
import {getLocalData, setLocalData} from '../../services/StorageServices'
import {toaster} from '../../shared/PFToast'
import NavBar from './NavBar'
import MockupBottom from './artwork-editor/MockupBottom'
import MockupEditor from './artwork-editor/MockupEditor'
import PreviewArtworkContainer from './artwork-editor/preview/PreviewArtworkContainer'
import {useSelectedVariantContext} from './context/SelectedVariantContext'
import {
    actionInitProduct,
    actionSetIsAdmin,
    actionChangeTabDesign,
    actionChangeSelectedLayer
} from './context/SelectedVariantContext/action'
import ProductVariantPricingContainer from './product-variant-pricing/ProductVariantPricingContainer'
import UploadContainer from './upload/UploadContainer'
import classNames from 'classnames'
import Toolbar from './Toolbar'
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg'
import {ReactComponent as Menu} from '../../assets/icons/menu.svg'
import PreviewLiveArtworkContainer from './artwork-editor/preview-live/PreviewLiveArtworkContainer'

// import {data} from '../../data'

const Step2Container = ({productId}) => {
    const {
        state: {tabDesign, display, product},
        dispatch,
    } = useSelectedVariantContext()
    const [leftWidth, setLeftWidth] = useState(400); // Phần trăm độ rộng của cột trái
    const separatorRef = useRef(null);
    const containerRef = useRef(null);

    const env = getEnvironment()
    const [loading, setLoading] = useState(true)
    const designLibrary = getLocalData('designLibrary') || []

    const mockupData = product?.mockups[0]

    const handleMouseDown = (event) => {
        event.preventDefault();
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (event) => {
        if (!containerRef.current) return;
        const containerRect = containerRef.current.getBoundingClientRect();
        const newLeftWidth = event.clientX - containerRect.left;

        if (newLeftWidth > 300 && newLeftWidth < 600) {
            setLeftWidth(newLeftWidth);
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    }

    const onCloseSidebar = () => {
        dispatch(actionChangeSelectedLayer(''))
        dispatch(actionChangeTabDesign(''))
    }

    useEffect(() => {
        const _fetchProductCatalog = async () => {
            try {
                let response = null
                if (isBackofficeEnv()) {
                    const storeId = getCurrentStore() || ''
                    response = await getProductCatalogById(productId, storeId)
                } else {
                    response = await getWebsiteProductCatalogById(productId)
                    if (designLibrary.length > 0) {
                        const now = new Date()
                        const availableDesigns = designLibrary.filter((design) => {
                            const timeDifference = now - new Date(design.created)
                            const hoursDifference = timeDifference / (1000 * 60 * 60)
                            return hoursDifference <= 1
                        })
                        setLocalData('designLibrary', availableDesigns)
                    }
                }
                const {success, data, message} = response
                if (!success) throw new Error(message)
                const isAdmin = hasRole('admin')
                dispatch(actionSetIsAdmin(isAdmin))
                dispatch(actionInitProduct(data))
            } catch (error) {
                toaster({
                    type: 'error',
                    message: error.message,
                })
            } finally {
                setLoading(false)
            }
        }

        _fetchProductCatalog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, env, dispatch])

    const className = display === 'design' ? 'd-block' : 'd-none'

    return (
        <div className="Step2Container newVersion" style={{overflow: 'hidden'}}>
            {/* <DocTitleByStore title="Mockup generator" /> */}
            {loading ? (
                <div className="LoadingWrapper">
                    <Spinner style={{color: '#007BFF'}} />
                </div>
            ) : product ? (
                <Fragment>
                    <NavBar />
                    <div className={classNames('Wrapper', {Pricing: display === 'pricing'})}
                         ref={containerRef}
                    >
                        {display === 'design' && mockupData.meta.version === 'v3' && !!mockupData.preview_v3 && (
                            <>
                                <div id='left-sidebar' className='position-relative pt-3 Left'
                                    style={{width: `${leftWidth}px`}}>
                                    <PreviewLiveArtworkContainer />
                                </div>
                                <div className='separator' ref={separatorRef} onMouseDown={handleMouseDown}>
                                    <Menu/>
                                </div>
                            </>
                        )}
                        <div className='Center NewVersion'>
                            <div className={className}>
                                <MockupEditor product={product} />
                            </div>
                            {display === 'preview' && <PreviewArtworkContainer display={display} />}
                            {['design', 'preview'].includes(display) && <MockupBottom />}
                            {display === 'pricing' && <ProductVariantPricingContainer display={display} />}
                        </div>

                        {(display !== 'design' || (display === 'design' && tabDesign)) && (
                            <div id='right-sidebar' className='position-relative Right'>
                                <UploadContainer />
                                {(tabDesign && display === 'design') && <div className='iconCloseSidebar'
                                                   onClick={onCloseSidebar}>
                                    <ArrowRight />
                                </div>}
                            </div>
                        )}

                        {display === 'design' && (
                            <div id='toolbar' className='Toolbar'>
                                <Toolbar />
                            </div>
                        )}
                    </div>
                </Fragment>
            ) : (
                <Fragment />
            )}
        </div>
    )
}

export default Step2Container
