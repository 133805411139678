import React, {useEffect, useState} from 'react'
import {ReactComponent as KeyboardIcon} from '../../../assets/icons/keyboard.svg'
import {useEditorStageSides} from '../../../hooks/useEditorStageSides'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {actionInitAppliedVariants} from '../context/SelectedVariantContext/action'
import EditorWrapper from './EditorWrapper'
import MockupAllAreas from './MockupAllAreas'
import MockupEditorToolbar from './MockupEditorToolbar'
import MockupStage from './MockupStage'
import SwitchBetweenSides from './SwitchBetweenSides'
import {useEditorSides} from '../../../hooks/useEditorSides'
import MockupSafeZone from './MockupSafeZone'
import {Button, PopoverBody, UncontrolledPopover} from 'reactstrap'
import ShortcutHelper from './ShortcutHelper'

const MockupEditor = () => {
    const {
        state: {editorWrapper, selectedAttribute, isAdmin},
        dispatch
    } = useSelectedVariantContext()

    const [firstInit, setFirstInit] = useState(false)

    const {isOptionalSide, selectedSide} = useEditorSides()

    useEffect(() => {
        if (!firstInit && editorWrapper.width && editorWrapper.height) {
            dispatch(actionInitAppliedVariants())
            setFirstInit(true)
        }
    }, [dispatch, editorWrapper.width, editorWrapper.height, firstInit])

    const stageSides = useEditorStageSides()

    const isShowSafeZone = isAdmin && selectedSide === 'Safezone'

    return (
        <EditorWrapper id="MockupEditor" classes={`MockupEditor`}>
            {selectedSide === 'All' && <MockupAllAreas activeSide={selectedSide} classes={`MockupAllAreas`} />}
            {isShowSafeZone && <MockupSafeZone activeSide={selectedSide} classes={`MockupAllAreas`} />}
            {/* <div className={isOptionalSide ? 'd-none' : 'd-block'}> */}
            <SwitchBetweenSides />
            <div className={'ExtraInformation'}>
                <Button
                    id={'ShortcutHelperPopover'}
                    color={'default'}
                    className={'Icon shadow-1'}
                >
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <KeyboardIcon style={{
                            fontSize: '1.5rem'
                        }} />
                    </div>

                </Button>
                <UncontrolledPopover
                    target={'ShortcutHelperPopover'}
                    trigger={'focus'}
                    placement={'top'}
                >
                    <PopoverBody className={'shadow-2'}>
                        <ShortcutHelper />
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
            {stageSides.map((data, index) => {
                if (index === 0) console.log('data.layers)', data[0].layers)
                return data?.map((stage, index) => (
                    <MockupStage
                        key={index}
                        stage={stage}
                        isOptionalSide={isOptionalSide}
                        isActive={
                            selectedSide === stage.side && stage.attributeIds.includes(selectedAttribute?._id)
                        }
                    />
                ))
            })}

            <MockupEditorToolbar />
        </EditorWrapper>
    )
}

export default MockupEditor

