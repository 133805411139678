import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {determineActualTextPosition, loadCustomFont} from '../components/step2/context/SelectedVariantContext/helper'
import {updateLayerAttribute} from '../components/step2/context/SelectedVariantContext/action'

const useFont = () => {
    const {dispatch, state: {fonts}} = useSelectedVariantContext()
    const determineLayerSize = (text, fontFamily, fontSize, align, rotation, width, height, x, y) => {
        const {width: newWidth, height: newHeight} = determineActualTextPosition(
            text,
            fontFamily,
            fontSize,
            align,
            rotation,
            width,
            height,
            x,
            y
        )
        return {
            width: newWidth,
            height: newHeight
        }
    }

    const changeFont = textLayer => async font => {
        const {x, y, fontFamily, fontStyle, fontWeight, rotation, linkFont, fontId} = font
        const {text, fontSize, align} = textLayer
        await loadCustomFont(fontFamily, linkFont)
        textLayer.fontFamily = fontFamily
        textLayer.fontStyle = fontStyle
        textLayer.fontWeight = fontWeight
        textLayer.linkFont = linkFont
        const {width, height} = determineLayerSize(
            text,
            fontFamily,
            fontSize,
            align,
            rotation,
            textLayer.width,
            textLayer.height,
            x,
            y
        )
        const offsetX = width / 2
        const offsetY = height / 2
        dispatch(updateLayerAttribute({...textLayer, width, height, offsetX, offsetY, fontId}))

    }


    return {
        fonts,
        changeFont
    }


}

export default useFont;