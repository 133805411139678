import classnames from 'classnames'
import React, {useMemo} from 'react'
import {useEditorSides} from '../../../../../hooks/useEditorSides'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import CampaignDesignItem from './CampaignDesignItem'

const CampaignItem = ({fonts}) => {

    /**
     * @type {State} state
     */
    const {
        state: {display}
    } = useSelectedVariantContext()

    const {isOptionalSide} = useEditorSides()

    const isDesignMode = useMemo(() => {
        return display === 'design' && !isOptionalSide
    }, [display, isOptionalSide])

    return (
        <div className={classnames('CampaignItem', {active: true})}>
            {isDesignMode && <CampaignDesignItem fonts={fonts} />}
        </div>
    )
}

export default CampaignItem
