import randomstring from 'randomstring'
import React, {Fragment, memo, useState} from 'react'
import {CompactPicker} from 'react-color'
import {Button, ButtonGroup, UncontrolledPopover} from 'reactstrap'
import {UncontrolledTooltip} from 'reactstrap/lib'
import {updateLayerAttribute} from '../../../context/SelectedVariantContext/action'
import {CMYK_COLORS} from '../../../context/SelectedVariantContext/constants'
import {
    capitalizeText,
    determineActualTextPosition,
    loadCustomFont,
    turnCmykToHex,
    turnHexToCmyk
} from '../../../context/SelectedVariantContext/helper'
import LayerFontSelect from './LayerFontSelect'
import {toaster} from '../../../../../shared/PFToast'
import {getLocalData} from '../../../../../services/StorageServices'
import {
    getLinksUploadArtwork,
    uploadArtworkMultiFonts,
    uploadTempArtwork
} from '../../../../../services/ArtworkTemplateServices'
import PubSub from '../../../Broker'

const CampaignDesignTextItem = memo((props) => {
    const {selectedLayer, fonts, dispatch} = props
    const [loading, setLoading] = useState(false)

    const determineLayerSize = (text, fontFamily, fontSize, align, rotation, width, height, x, y) => {
        const {width: newWidth, height: newHeight} = determineActualTextPosition(
            text,
            fontFamily,
            fontSize,
            align,
            rotation,
            width,
            height,
            x,
            y
        )
        return {
            width: newWidth,
            height: newHeight
        }
    }

    const _handleChangeFont = async (font) => {
        const {x, y, fontFamily, fontStyle, fontWeight, rotation, linkFont, fontId} = font
        const {text, fontSize, align} = selectedLayer
        await loadCustomFont(fontFamily, linkFont)
        selectedLayer.fontFamily = fontFamily
        selectedLayer.fontStyle = fontStyle
        selectedLayer.fontWeight = fontWeight
        selectedLayer.linkFont = linkFont
        const {width, height} = determineLayerSize(
            text,
            fontFamily,
            fontSize,
            align,
            rotation,
            selectedLayer.width,
            selectedLayer.height,
            x,
            y
        )
        const offsetX = width / 2
        const offsetY = height / 2
        dispatch(updateLayerAttribute({...selectedLayer, width, height, offsetX, offsetY, fontId}))
    }

    const _onTextTransform = (transform) => {
        let text = ''
        let textTransform = selectedLayer.text
        switch (transform) {
            case 'asTyped':
                text = selectedLayer.asTyped
                break
            case 'uppercase':
                textTransform = textTransform.toUpperCase()
                text = textTransform
                break
            case 'capitalize':
                textTransform = capitalizeText(textTransform)
                text = textTransform
                break
            case 'lowercase':
                textTransform = textTransform.toLowerCase()
                text = textTransform
                break
            default:
                break
        }
        const {fontFamily, fontSize, align, rotation, x, y} = selectedLayer
        const {width, height} = determineLayerSize(
            text,
            fontFamily,
            fontSize,
            align,
            rotation,
            selectedLayer?.width,
            selectedLayer?.height,
            x,
            y
        )
        const updatedAttribute = {
            ...selectedLayer,
            width,
            height: height + 5,
            text,
            textTransform
        }
        dispatch(updateLayerAttribute(updatedAttribute))
    }

    const _handleColorPicker = (color) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        let selectedColor = '#000000'
        if (color.hex !== '#000000') {
            // convert hex từ color picker sang cmyk để giảm mã màu
            const hexTocmyk = turnHexToCmyk(color.hex)

            // sau đó đổi lại từ cmyk sang hex để set vào layer (vì layer không nhận mã màu cmyk)
            selectedColor = turnCmykToHex(hexTocmyk)
        }
        selectedLayer.fill = selectedColor
        selectedLayer.textColor = selectedColor
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const fontRandomId = randomstring.generate(7)

    const _fetchUploadLink = async (limit) => {
        try {
            const {success, data} = await getLinksUploadArtwork({limit})
            if (!success) {
                return
            }
            return data
        } catch (error) {
        }
    }

    const _validateFile = (file) => {
        if (!file) return
        let error = ''
        const fileType = file.name.split('.')[1]
        if (!['ttf', 'otf', 'TTF', 'OTF'].includes(fileType)) {
            error = 'Your uploaded font file is not supported, please try .ttf or .otf file'
        } else if (file.size > 25 * 1024 * 1024) {
            error = 'Your file exceeded allowed limit of 25MB'
        }
        return error
    }

    const _generateMessageUploadFont = (errorFiles) => {
        if (errorFiles.length === 0) {
            toaster({
                type: 'success',
                message: 'Font updates successfully'
            })
        } else {
            toaster({
                type: 'warning',
                message: 'Some of file fonts failed to upload'
            })
        }
    }

    const handleUploadFont = async (e) => {
        const {files} = e.target
        const _files = [...files]
        setLoading(true)
        const accessToken = getLocalData('accessToken')
        if (!accessToken) {
            let errorFiles = []
            const fontUrl = await _fetchUploadLink(_files.length)
            const operators = []
            fontUrl.map(async (url, index) => {
                const currentFile = _files[index]
                const errorValidate = _validateFile(currentFile)
                if (errorValidate) {
                    errorFiles.push(_files[index])
                } else {
                    operators.push(uploadFontToS3({url: url, file: _files[index]}))
                }
            })
            await Promise.all(operators)
                .then((urls) => {
                    urls.forEach((item, index) => {
                        const customFonts = !!sessionStorage.getItem('customFonts')
                            ? JSON.parse(sessionStorage.getItem('customFonts'))
                            : {}
                        const fileName = _files[index].name.split('.')[0]
                        customFonts[fileName] = {
                            category: 'other',
                            files: item.split('?')[0],
                            variants: '',
                            subsets: 'latin'
                        }
                        sessionStorage.setItem('customFonts', JSON.stringify(customFonts))
                    })
                })
                .catch((e) => console.log(e))
                .finally(async () => {
                    setLoading(false)
                    _generateMessageUploadFont(errorFiles)
                    await PubSub.publish('LOAD_FONT')
                })
        } else {
            try {
                const formData = new FormData()
                for (let i = 0; i < files.length; i++) {
                    formData.append('fonts', files[i])
                }
                const {data, success, message} = await uploadArtworkMultiFonts(formData)
                if (!success) {
                    throw new Error(message)
                }
                const errorFiles = data.filter((item) => {
                    const isDuplicated = item.isDuplicated === undefined ? true : item.isDuplicated
                    return !isDuplicated
                })
                _generateMessageUploadFont(errorFiles)
                await PubSub.publish('LOAD_FONT')
            } catch (error) {
                toaster({
                    type: 'error',
                    message: error.message
                })
            } finally {
                setLoading(false)
            }
        }
    }

    const uploadFontToS3 = (data) => {
        return new Promise(async (resolve, reject) => {
            const {status} = await uploadTempArtwork(data.url, data.file, '')
            if (status !== 200) {
                reject('Something wrong, please try again')
            }
            resolve(data.url)
        })
    }

    return (
        <div className={`TextDesign ${selectedLayer && selectedLayer?.layerType === 'text' ? 'd-block' : 'd-none'}`}>
            <input
                hidden
                type="file"
                accept=".ttf,.otf"
                multiple={true}
                onChange={(e) => {
                    handleUploadFont(e)
                    e.target.value = null
                }}
                id="inputUploadFont"
            />
            <div className="form-group">
                <Button
                    color="default"
                    disabled={loading}
                    className="w-100 p-1 mb-2"
                    id="inputUploadFont"
                    htmlFor="inputUploadFont"
                >
                    <label id="inputUploadFont" htmlFor="inputUploadFont" className="cursor-pointer w-100">
                        {loading ? (
                            <i className="fas fa-circle-notch fa-spin spin-icon me-2"></i>
                        ) : (
                            <i className="fa fa-plus me-2" />
                        )}
                        Add font
                    </label>
                </Button>
                <div className={`d-flex align-items-center mb-3 ${selectedLayer?.isLock ? 'FontDisabled' : ''}`}>
                    <LayerFontSelect
                        fonts={fonts}
                        itemData={selectedLayer || {}}
                        fontRandomId={fontRandomId}
                        handleChangeFont={_handleChangeFont}
                    />
                    <div id="color_pick_text" className="ms-2 layer_edit">
                        <div className="Icon" style={{backgroundColor: selectedLayer?.fill || '#000000'}}></div>

                        <UncontrolledPopover trigger="legacy" placement="left" target="color_pick_text">
                            <CompactPicker
                                color={selectedLayer?.fill || '#000000'}
                                colors={CMYK_COLORS}
                                onChange={_handleColorPicker}
                            />
                        </UncontrolledPopover>
                        <UncontrolledTooltip target="color_pick_text">Change text color</UncontrolledTooltip>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-sm-6">
                    <ButtonGroup>
                        <Button
                            outline
                            id="asTyped"
                            className="pt-2"
                            disabled={selectedLayer?.isLock}
                            onClick={() => _onTextTransform('asTyped')}
                        >
                            <i className="text-dark fa fa-minus" />
                        </Button>
                        <Button
                            id="uppercase"
                            outline
                            className="text-dark"
                            disabled={selectedLayer?.isLock}
                            onClick={() => _onTextTransform('uppercase')}
                        >
                            AA
                        </Button>
                        <Button
                            id="capitalize"
                            outline
                            className="text-dark"
                            disabled={selectedLayer?.isLock}
                            onClick={() => _onTextTransform('capitalize')}
                        >
                            Aa
                        </Button>
                        <Button
                            id="lowercase"
                            outline
                            className="text-dark"
                            disabled={selectedLayer?.isLock}
                            onClick={() => _onTextTransform('lowercase')}
                        >
                            aa
                        </Button>
                    </ButtonGroup>
                    <ButtonTooltip />
                </div>
            </div>
        </div>
    )
})

const ButtonTooltip = () => {
    const tooltips = [
        {
            target: 'asTyped',
            label: 'As typed'
        },
        {
            target: 'uppercase',
            label: 'Uppercase'
        },
        {
            target: 'capitalize',
            label: 'Capitalize'
        },
        {
            target: 'lowercase',
            label: 'Lowercase'
        }
    ]

    return (
        <Fragment>
            {tooltips.map((tooltip) => (
                <UncontrolledTooltip key={tooltip.target} placement="top" target={tooltip.target}>
                    {tooltip.label}
                </UncontrolledTooltip>
            ))}
        </Fragment>
    )
}

export default CampaignDesignTextItem

