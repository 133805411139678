import classnames from 'classnames'
import React from 'react'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {replaceUnderline} from '../context/SelectedVariantContext/helper'
import {actionChangeTabDesign, updateActiveSide} from '../context/SelectedVariantContext/action'
import {useEditorSides} from '../../../hooks/useEditorSides'
import { isBackofficeEnv } from '../../../helpers/isBackoffceEnv'

const SwitchBetweenSides = () => {
    const {
        state: {selectedSide, display, runningImageCompress, isAdmin},
        dispatch,
    } = useSelectedVariantContext()
    const {sides} = useEditorSides()

    const changeSide = async (tab) => {
        if (tab === selectedSide) return
        dispatch(updateActiveSide(tab))
        dispatch(actionChangeTabDesign(''))
    }

    return (
        <div className="SwitchBetweenSides">
            {display === 'design' && (
                <div className="Sides">
                    {sides?.map((sideType) => (
                        <button
                            type="button"
                            key={sideType}
                            className={classnames('Side', {active: selectedSide === sideType})}
                            onClick={() => {
                                changeSide(sideType)
                            }}
                            disabled={runningImageCompress}
                        >
                            {replaceUnderline(sideType)}
                        </button>
                    ))}

                    <button
                        type="button"
                        className={classnames('Side AllAreas', {active: selectedSide === 'All'})}
                        onClick={() => {
                            changeSide('All')
                        }}
                        disabled={runningImageCompress}
                    >
                        All areas
                    </button>

                    {isAdmin && isBackofficeEnv() && (
                        <button
                            type="button"
                            className={classnames('Side AllAreas', {active: selectedSide === 'Safezone'})}
                            onClick={() => {
                                changeSide('Safezone')
                            }}
                            disabled={runningImageCompress}
                        >
                            Safe zone
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default SwitchBetweenSides

