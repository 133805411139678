import React, {Fragment} from 'react'
import {IMAGE_TYPES, MAX_IMAGE_UPLOAD_SIZE, SVG_TYPE} from '../../../../../../constant'
import {toaster} from '../../../../../../shared/PFToast'
import {useSelectedVariantContext} from '../../../../context/SelectedVariantContext'
import {
    actionUpdateBgImage, actionUpdateListBbImages, actionUpdateLoadingBackground
} from '../../../../context/SelectedVariantContext/action'
import {fileToBase64, getImageDimensions} from '../../../../../../helpers/imageFormat'
import randomstring from 'randomstring'
import {getInfomationBackground} from '../../../../../../helpers/createProductCombine'
import {ReactComponent as ComputerIcon} from '../../../../../../assets/icons/computer.svg'

const UploadByComputer = (props) => {
    const {toggle, accept, wPreview = 600, hPreview = 600} = props
    const {
        state: {currentBgImageType, listBackgroundImages},
        dispatch
    } = useSelectedVariantContext()
    // const {uploadFile} = useUploadFile()
    // const {uploadBackground} = useUploadBackground()

    const _validateFileUpload = (file) => {
        let isValid = true
        if (!IMAGE_TYPES.includes(file.type) && file.type !== SVG_TYPE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'The image file is not supported',
            })
        }
        if (file.size > MAX_IMAGE_UPLOAD_SIZE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'The image file exceeded the maximum limit 50Mb',
            })
        }
        return isValid
    }

    const _handleChangeFile = (e) => {
        const {files} = e.target

        _onChangeFile(files)
    }

    const _onChangeFile = async (files) => {
        const file = files[0]
        if (!_validateFileUpload(file)) return

        toggle()
        dispatch(actionUpdateLoadingBackground(true))

        const fileBase64 = await fileToBase64(file)
        const dimensions = await getImageDimensions(fileBase64)
        const key = randomstring.generate(7)
        const info = await getInfomationBackground(currentBgImageType, {
            naturalWidth: dimensions.w,
            naturalHeight: dimensions.h,
        }, wPreview, hPreview)
        const {width, height, x, y} = info
        const backgroundImages = {
            naturalWidth: dimensions.w,
            naturalHeight: dimensions.h,
            id: `backgroundImage`,
            file: fileBase64,
            key,
            width,
            height,
            x,
            y,
        }
        await dispatch(actionUpdateBgImage(backgroundImages))
        await dispatch(actionUpdateListBbImages([...listBackgroundImages, {
            file: fileBase64,
            key,
            naturalWidth: dimensions.w,
            naturalHeight: dimensions.h,
        }]))
        // dispatch(actionUpdateLoadingBackground(true))
        // dispatch(actionSetRunningImageCompress(true))
        // toggle()
        // await uploadBackground(file)
        // dispatch(actionUpdateLoadingBackground(false))
    }

    return (
        <Fragment>
            <div className="UploadFromLocal">
                <label>Upload from:</label>
                <div className="YourDevice d-flex align-items-center">
                    <input
                        type='file'
                        accept={accept || 'image/png, image/jpeg, image/jpg'}
                        multiple={false}
                        onChange={_handleChangeFile}
                        style={{width: 100}}
                        id="inputUploadArtwork_computer"
                        className="d-none"
                        disabled={false}
                    />
                    <label htmlFor="inputUploadArtwork_computer" id="inputUploadArtwork_computer"
                           className="mb-0 cursor-pointer align-items-center">
                        <ComputerIcon className="mr-2"/>
                        Your device
                    </label>
                </div>
            </div>
        </Fragment>
    )
}

export default UploadByComputer

