/**
 * @typedef {Record<string, {parts:[{x: number, y: number, width: number, height: number}]}>} UVConfigs
 */

import {
    ActionManager,
    ArcRotateCamera,
    Color3,
    Color4,
    DynamicTexture,
    Engine,
    ExecuteCodeAction,
    HemisphericLight,
    HighlightLayer,
    Scene,
    SceneLoader,
    Texture,
    Vector3,
} from '@babylonjs/core'
import '@babylonjs/loaders'

class Babylon {
    static CANVAS_WIDTH = 2048
    static CANVAS_HEIGHT = 2048
    static DESIGN_BACKGROUND = '#ffffff'
    static DEFAULT_CAMERA_RADIUS = 6

    /**
     * 
     * @param {HTMLCanvasElement} renderCanvas
     * @param {UVConfigs} uvConfigs
     */
    constructor(renderCanvas, uvConfigs) {
        /** @type {HTMLCanvasElement} */
        this.designCanvas = new OffscreenCanvas(Babylon.CANVAS_WIDTH, Babylon.CANVAS_HEIGHT)
        this.renderCanvas = renderCanvas

        /** @type {UVConfigs} */
        this.uvConfigs = uvConfigs

        // engine
        const workCanvas = document.createElement('canvas')
        this.engine = new Engine(workCanvas, true, {
            useHighPrecisionMatrix: true,
            premultipliedAlpha: false,
            preserveDrawingBuffer: false,
            antialias: true,
            forceSRGBBufferSupportState: false,
            stencil: true,
        })
        this.engine.inputElement = this.renderCanvas

        // scene
        this.scene = new Scene(this.engine)

        // main camera
        this.mainCamera = new ArcRotateCamera('MainCamera', Math.PI/2, Math.PI/2, Babylon.DEFAULT_CAMERA_RADIUS, Vector3.Zero(), this.scene)
        this.mainCamera.attachControl(this.renderCanvas, true)
        this.engine.registerView(this.renderCanvas)

        // highlight layer
        this.highlightLayer = new HighlightLayer('HighlightLayer', this.scene, {
            camera: this.mainCamera,
            isStroke: true,
            mainTextureRatio: 4,
        })

        // design texture
        this.designTexture = new DynamicTexture('DesignTexture', this.designCanvas, {
            scene: this.scene,
            samplingMode: Texture.NEAREST_LINEAR,
        })

        this.init()
    }

    init() {
        // rendering background
        this.scene.clearColor = new Color4(1, 1, 1, 1)

        // main camera
        this.mainCamera.lowerRadiusLimit = 3
        this.mainCamera.upperRadiusLimit = 20

        // light
        const light = new HemisphericLight('Light', new Vector3(0, 1, 0), this.scene)
        light.intensity = 0.9

        this.clearDesignCanvas()
    }

    attachMockupCamera(canvasId, options) {
        const {
            horizontalRotation = 0,
            verticalRotation = 0,
            radius = Babylon.DEFAULT_CAMERA_RADIUS
        } = options
        this.mockupCamera = new ArcRotateCamera(
            'MockupCamera',
            horizontalRotation, verticalRotation, radius,
            Vector3.Zero(),
            this.scene,
        )
        const canvas = document.getElementById(canvasId)
        this.engine.registerView(canvas, this.mockupCamera)
    }

    updateMockupCameraPosition(horizontalRotation, verticalRotation, radius) {
        const x = radius * Math.sin(verticalRotation) * Math.cos(horizontalRotation)
        const y = radius * Math.cos(verticalRotation)
        const z = radius * Math.sin(verticalRotation) * Math.sin(horizontalRotation)
        this.mockupCamera.position = new Vector3(x, y, z)
        // const target = BABYLON.Vector3.Zero();  // Example target (e.g., the origin)
        // camera.setTarget(target);
    }

    run() {
        this.engine.runRenderLoop(() => {
            if (this.scene.activeCamera) {
                this.scene.render()
            }
        })

        const container = document.getElementById('preview_live_artwork_container')
        this.resizeObserver = new ResizeObserver(() => {
            this.engine.resize();
        })
        this.resizeObserver.observe(container)
    }

    destroy() {
        this.engine.dispose()
        this.resizeObserver.disconnect()
    }

    async loadModel(modelUrl) {
        const result = await SceneLoader.ImportMeshAsync('', modelUrl, undefined, this.scene)

        result.meshes.forEach((mesh) => {
            this.handleMesh(mesh)
        })

        this.scene.freeActiveMeshes()
    }

    async loadMaterialTexture(materialUrl) {
        this.bumpTexture = new Texture(materialUrl)
        this.bumpTexture.uScale = 3
        this.bumpTexture.vScale = 3
    }

    handleMesh(mesh) {
        if (!mesh || !mesh.material) return

        const material = mesh.material

        material.albedoTexture = this.designTexture
        material.bumpTexture = this.bumpTexture
        
        material.metallic = 0
        material.roughness = 0
        material.metallicF0Factor = 0

        mesh.actionManager = new ActionManager(this.scene)
        mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (_ev) => {
            this.highlightLayer.addMesh(mesh, Color3.Yellow())
        }))
        mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (_ev) => {
            this.highlightLayer.removeMesh(mesh)
        }))
    }

    updateDesignTexture() {
        this.designTexture.update()
    }

    clearDesignCanvas(x = 0, y = 0, w = Babylon.CANVAS_WIDTH, h = Babylon.CANVAS_HEIGHT) {
        const ctx = this.designCanvas.getContext('2d')
        ctx.fillStyle = Babylon.DESIGN_BACKGROUND
        ctx.fillRect(x, y, w, h)
    }

    drawImage(image, x, y, w, h) {
        const ctx = this.designCanvas.getContext('2d')
        ctx.setTransform(1, 0, 0, -1, 0, Babylon.CANVAS_HEIGHT)
        this.clearDesignCanvas(x, y, w, h)
        ctx.drawImage(image, x, y, w, h)
        ctx.setTransform(1, 0, 0, 1, 0, Babylon.CANVAS_HEIGHT)
    }

    drawUV(image, side, index) {
        const uvConfig = this.uvConfigs[side].parts[index]
        this.drawImage(image, uvConfig.x, uvConfig.y, uvConfig.width, uvConfig.height)
        this.updateDesignTexture()
    }
}

let babylon = null

/**
 * @param {HTMLCanvasElement} [renderCanvas]
 * @param {UVConfigs} [uvConfigs]
 * @returns {Babylon}
 */
export const getBabylonInstance = (renderCanvas, uvConfigs) => {
    if (babylon) return babylon

    if (renderCanvas && uvConfigs) {
        babylon = new Babylon(renderCanvas, uvConfigs)
        return babylon
    }

    throw new Error('BabylonJS is not initialized')
}
