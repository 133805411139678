import classnames from 'classnames'
import React, {Fragment, useEffect, useState} from 'react'
import {Button, ButtonGroup, Input, InputGroup, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as Minus} from '../../../assets/icons/minus.svg'
import {ReactComponent as Palm} from '../../../assets/icons/palm.svg'
import {ReactComponent as Plus} from '../../../assets/icons/plus.svg'
import {ReactComponent as Redo} from '../../../assets/icons/redo.svg'
import {ReactComponent as Undo} from '../../../assets/icons/undo.svg'
import {useEditorBackground} from '../../../hooks/useEditorBackground'
import {useEditorSides} from '../../../hooks/useEditorSides'
import {useEditorStageRef} from '../../../hooks/useEditorStageRef'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionChangeSelectedLayer,
    actionChangeStageDrag,
    actionRedo,
    actionUndo,
} from '../context/SelectedVariantContext/action'
import {MOCKUP_BOTTOM_TOOLTIPS} from '../context/SelectedVariantContext/constants'
import {hideToolbar, roundDecimal} from '../context/SelectedVariantContext/helper'
import DpiImage from './DpiImage'
import MockupGuideline from './MockupGuideline'
import PreviewArtworkDownload from './preview/PreviewArtworkDownload'

const MockupBottom = () => {
    const {
        state: {selectedLayer, stageDrag, display, history},
        dispatch,
    } = useSelectedVariantContext()

    const {isOptionalSide} = useEditorSides()

    /**
     * @type {import('konva/types/Stage').Stage}
     */
    const stageRef = useEditorStageRef()
    const ratio = Math.min(stageRef?.scaleX(), stageRef?.scaleY())

    const {ratioDefault = 1} = useEditorBackground()

    const [openGuideline, setOpenGuideline] = useState(false)
    const getWindowWidth = () => {
        const {innerWidth: width} = window
        return width
    }

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const onToggleDragStage = () => {
        if (!stageRef) return
        dispatch(actionChangeStageDrag())
    }

    /**
     * @param {'increase'|'decrease'} type
     */
    const onScaleStage = (type) => {
        if (!stageRef) return
        dispatch(actionChangeSelectedLayer(null))

        const scaleFactor = type === 'increase' ? 1.2 : 1 / 1.2

        // Calculate the new scale
        let newScale = ratio * scaleFactor
        if (newScale < ratioDefault / 5) {
            return
        } else if (newScale > ratioDefault * 10) {
            newScale = ratioDefault * 10
        } else {
            const centerWidth = stageRef.width() / 2
            const centerHeight = stageRef.height() / 2

            const newX = centerWidth - (centerWidth - stageRef.x()) * scaleFactor
            const newY = centerHeight - (centerHeight - stageRef.y()) * scaleFactor
            stageRef.position({x: newX, y: newY})
        }

        stageRef.scale({x: newScale, y: newScale})
        stageRef.batchDraw()
    }

    const handleChangeHistory = (action) => {
        hideToolbar()
        if (action === 'undo') {
            dispatch(actionUndo())
        } else {
            dispatch(actionRedo())
        }
    }

    const toggleGuideline = () => setOpenGuideline(!openGuideline)

    const iconSize = windowWidth < 1400 ? 15 : 20
    const zoomSize = windowWidth < 1400 ? 12 : 16

    return (
        <Fragment>
            {isOptionalSide ? (
                <div></div>
            ) : (
                <Fragment>
                    {display === 'preview' ? (
                        <PreviewArtworkDownload />
                    ) : (
                        <div className="MockupBottom">
                            <MockupGuideline isOpen={openGuideline} toggle={toggleGuideline} />
                            <div className="BottomLeft d-flex align-items-center">
                                {history && display === 'design' && (
                                    <div className="MockupUndo me-2">
                                        <ButtonGroup className="my-2">
                                            <Button
                                                outline
                                                id="undo"
                                                disabled={history.step === 0}
                                                onClick={() => handleChangeHistory('undo')}
                                            >
                                                <Undo width={iconSize} height={iconSize} />
                                            </Button>
                                            <Button
                                                outline
                                                id="redo"
                                                onClick={() => handleChangeHistory('redo')}
                                                disabled={
                                                    history.step === history.logs.length - 1 ||
                                                    history.logs.length === 0
                                                }
                                            >
                                                <Redo width={iconSize} height={iconSize} />
                                            </Button>
                                        </ButtonGroup>
                                        <UncontrolledTooltip placement="top" target="undo">
                                            {history.step === 0 ? 'No history to undo' : 'Undo (Ctrl + Z)'}
                                        </UncontrolledTooltip>
                                        <UncontrolledTooltip placement="top" target="redo">
                                            {history.step === history.logs.length - 1 || history.logs.length === 0
                                                ? 'No history to redo'
                                                : 'Redo (Ctrl + Y)'}
                                        </UncontrolledTooltip>
                                    </div>
                                )}

                                {selectedLayer?.layerType === 'image' && (
                                    <DpiImage layer={selectedLayer} classes="BottomImageDpi ms-2" />
                                )}
                            </div>

                            <div className="BottomRight d-flex">
                                <div
                                    id="drag_editor"
                                    className={classnames('MockupDrag me-2 editor_below', {Active: stageDrag})}
                                    onClick={onToggleDragStage}
                                >
                                    <Palm width={iconSize} height={iconSize} />
                                </div>
                                <div className="MockupZoom">
                                    <InputGroup>
                                        <Button
                                            color="default"
                                            id="btn_minus"
                                            className="btn-minus"
                                            onClick={() => onScaleStage('decrease')}
                                        >
                                            <Minus width={zoomSize} height={zoomSize} />
                                        </Button>
                                        <Input
                                            className="TextRatio"
                                            type="text"
                                            value={`${ratio ? roundDecimal(ratio * 100) : 0}%`}
                                            readOnly
                                        />
                                        <Button
                                            id="btn_plus"
                                            className="btn-plus"
                                            color="default"
                                            onClick={() => onScaleStage('increase')}
                                        >
                                            <Plus width={zoomSize} height={zoomSize} />
                                        </Button>
                                    </InputGroup>
                                </div>
                            </div>
                            {MOCKUP_BOTTOM_TOOLTIPS.map((tooltip) => (
                                <UncontrolledTooltip key={tooltip.target} placement="top" target={tooltip.target}>
                                    {tooltip.title}
                                </UncontrolledTooltip>
                            ))}
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default MockupBottom
