import React, {Fragment, useCallback, useEffect, useState} from 'react'
import CampaignItem from './CampaignItem'
import useUploadFile from '../../../../../hooks/useUploadFile'
import CampaignDesignLoading from './CampaignDesignLoading'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import {getArtworkFonts} from '../../../../../services/ArtworkTemplateServices'
import Axios from 'axios'
import PubSub from '../../../Broker'
import {getLocalData} from '../../../../../services/StorageServices'
import {useEditorBackground} from '../../../../../hooks/useEditorBackground'
import ProductVariantContainer from './../../../product-variant/ProductVariantContainer'
import ModalBackgroundColor from './ModalBackgroundColor'
import {updateFonts} from '../../../context/SelectedVariantContext/action'

const PrintFileRequirement = ({selectedSide, selectedAttribute, background, product, className}) => {
    return (
        <Fragment>
            {background && (
                <div className={`PrintFileRequirement ${className}`}>
                    <h4 className="fs-16">Print file requirements</h4>
                    <ul>
                        <li><span style={{color: '#303238'}}>File type:</span> JPG, JPEG, PNG and SVG file types supported</li>
                        <li><span style={{color: '#303238'}}>Print area size:</span> Maximum 50 MB (JPG, PNG) or 20 MB (SVG)
                        </li>
                        {selectedSide === 'All' ? (
                            <li>
                                <span style={{color: '#303238'}}>File size:</span> Print area
                                size {selectedAttribute?.mockup?.preview?.Front?.image_width} x{' '}
                                {selectedAttribute?.mockup?.preview?.Front?.image_height} px (
                                {selectedAttribute?.mockup?.dpi?.minimum} DPI)
                            </li>
                        ) : (
                            <li>
                                <span style={{color: '#303238'}}>File size:</span> Print area
                                size {background.width} x {background.height} px ({background.dpi} DPI)
                            </li>
                        )}

                        {product && product.mockup_and_templates_link && (
                            <li>
                                <a
                                    className="editor_below"
                                    href={product.mockup_and_templates_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    id={'download_template'}
                                >
                                    Click here
                                </a>{' '}
                                to download template & mockup
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </Fragment>
    )
}

const CampaignDesignContainer = () => {
    const {pending} = useUploadFile()
    const {
        state: {selectedSide, selectedAttribute, imageCompressProgress, runningImageCompress, product, tabDesign},
        dispatch
    } = useSelectedVariantContext()

    const [isMounted, setIsMounted] = useState(false)
    const [googleFonts, setGoogleFonts] = useState([])
    const [fonts, setFonts] = useState({
        customFonts: [],
        googleFonts: [],
    })

    const background = useEditorBackground()

    const _getGoogleFonts = async () => {
        if (!isMounted) {
            const googleFonts = await Axios.get(
                `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT_API_KEY}`
            )
            setGoogleFonts(googleFonts.data.items)
            return googleFonts.data.items
        } else {
            return googleFonts
        }
    }

    const _convertToVariantsArrayForExternalLibrary = (variants) => {
        return variants.map((variant) => {
            if (variant === 'italic') return '400i'
            else if (variant === 'regular') return '400'
            else if (variant.includes('italic')) return variant.replace('italic', 'i')
            return variant
        })
    }

    const _renderTabDesign = () => {
        switch (tabDesign) {
            case 'product':
                return (
                    <>
                        <ProductVariantContainer className='d-block' />
                        <PrintFileRequirement
                            selectedSide={selectedSide}
                            selectedAttribute={selectedAttribute}
                            background={background}
                            product={product}
                            className='d-block'
                        />
                    </>
                )
            case 'layer':
                return (
                    <div className='CampaignList'>
                        <h3>Layers</h3>
                        {' '}
                        <CampaignItem fonts={fonts} />
                    </div>
                )
            case 'background':
                return (
                    <ModalBackgroundColor/>
                )
            case 'addText':
                return (
                    <></>
                )
            default:
                return null
        }
    }

    const _renderFontOption = useCallback(async () => {
        let parseCustomFonts = {},
            googleFontItem = {}

        const googleFonts = await _getGoogleFonts()
        googleFonts.forEach((googleFont) => {
            const {family, files, subsets, category, variants} = googleFont
            const convertedVariantsArrayForExternalLibrary = _convertToVariantsArrayForExternalLibrary(variants)

            const replaceAttribute = (i, newI) => {
                files[newI] = files[i]
                delete files[i]
            }

            const replaceToHttps = (string) => string.replace('http://', 'https://')

            for (const i in files) {
                let newIndex = null
                if (i.indexOf('italic') > -1 && i.length > 6) {
                    newIndex = i.replace('italic', 'i')
                } else if (i === 'italic') newIndex = '400i'
                else if (i === 'regular') newIndex = '400'

                newIndex && replaceAttribute(i, newIndex)
                files[newIndex || i] = replaceToHttps(files[newIndex || i])
            }

            googleFontItem[family] = {
                category,
                files,
                variants: convertedVariantsArrayForExternalLibrary.join(','),
                subsets: subsets.join(','),
            }
        })

        const accessToken = getLocalData('accessToken')
        if(!accessToken) {
            const customFonts = !!sessionStorage.getItem('customFonts') ? JSON.parse(sessionStorage.getItem('customFonts')) : {}
            setFonts({
                customFonts: customFonts,
                googleFonts: googleFontItem,
            })

       } else {
            try {
                const {success, data, message} = await getArtworkFonts()

                if (!success) throw new Error(message)

                data.forEach((item) => {
                    parseCustomFonts[item.displayName] = {
                        category: 'other',
                        files: item.path,
                        variants: '',
                        subsets: 'latin',
                    }
                })
                setFonts({
                    customFonts: parseCustomFonts,
                    googleFonts: googleFontItem,
                })


            } catch (e) {
                // window.alert(e.message)
                console.error(e.message)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _updateFonts = useCallback(() => {
        dispatch(updateFonts(fonts))
    }, [fonts])

    useEffect(() => {
        const subscriber = PubSub.subscribe('LOAD_FONT', () => {
            _renderFontOption()
        })

        return () => {
            PubSub.unsubscribe(subscriber)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsMounted(true)

        _renderFontOption()

        return () => {
            setIsMounted(false)
        }
    }, [_renderFontOption])
    useEffect(() => {
        _updateFonts()
    }, [_updateFonts])

    return (
        <div className="CampaignDesignContainer">
            {pending || runningImageCompress ? (
                <CampaignDesignLoading percentage={imageCompressProgress} />
            ) : (
                <>
                    {_renderTabDesign()}



                </>
            )}
        </div>
    )
}

export default CampaignDesignContainer

