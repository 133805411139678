import React, {useState} from 'react'
import {ReactComponent as Shirt} from '../../assets/icons/shirt.svg'
import {ReactComponent as Upload} from '../../assets/icons/upload.svg'
import {ReactComponent as TextFields} from '../../assets/icons/text_fields.svg'
import {ReactComponent as Question} from '../../assets/icons/question.svg'
import {ReactComponent as ColorLens} from '../../assets/icons/color_lens.svg'
import {ReactComponent as Layers} from '../../assets/icons/all-layers.svg'
import MockupGuideline from './artwork-editor/MockupGuideline'
import {useSelectedVariantContext} from './context/SelectedVariantContext'
import {
    actionAddTextLayer,
    actionChangeSelectedLayer,
    actionChangeTabDesign
} from './context/SelectedVariantContext/action'
import DesignLibraryModal from './artwork-editor/design-library/DesignLibraryModal'

const Toolbar = () => {
    const {
        state: {tabDesign, selectedAttribute, selectedSide},
        dispatch
    } = useSelectedVariantContext()
    console.log('selectedAttribute', selectedAttribute)
    const background = selectedAttribute?.safeZone[selectedSide]
    const [isOpenImageDesign, setIsOpenImageDesign] = useState(false)
    const [openGuideline, setOpenGuideline] = useState(false)
    const toggleGuideline = () => setOpenGuideline(!openGuideline)

    const setTabDesign = (type) => {
        if (type !== 'layer') dispatch(actionChangeSelectedLayer(null))
        dispatch(actionChangeTabDesign(type))
    }

    const _toggle = () => {
        setIsOpenImageDesign((prev) => !prev)
    }

    const onAddText = () => {
        if (background) {
            dispatch(actionChangeTabDesign('layer'))
            dispatch(actionAddTextLayer())
        }
    }

    return (
        <>
            <ul>
                <li className={`text-center ${tabDesign === 'product' ? 'active' : ''}`}
                    onClick={() => setTabDesign('product')}>
                    <div className="Icon">
                        <Shirt />
                    </div>
                    <label>Product</label>
                </li>
                <li className={`text-center ${tabDesign === 'addImage' ? 'active' : ''}`} onClick={() => {
                    if (background) {
                        _toggle()
                    }
                }}>
                    <div className="Icon">
                        <Upload />
                    </div>
                    <label>Add image</label>
                </li>
                <li className={`text-center ${tabDesign === 'addText' ? 'active' : ''}`} onClick={onAddText}>
                    <div className="Icon">
                        <TextFields />
                    </div>
                    <label>Add text</label>
                </li>
                <li className={`text-center ${tabDesign === 'layer' ? 'active' : ''}`}
                    onClick={() => setTabDesign('layer')}>
                    <div className="Icon">
                        <Layers />
                    </div>
                    <label>Layers</label>
                </li>
                <li className={`text-center ${tabDesign === 'background' ? 'active' : ''}`}
                    onClick={() => setTabDesign('background')}>
                    <div className="Icon">
                        <ColorLens />
                    </div>
                    <label>Background color</label>
                </li>
                <li className="text-center" onClick={toggleGuideline}>
                    <div className="Icon">
                        <Question />
                    </div>
                    <label>Guideline</label>
                    <MockupGuideline isOpen={openGuideline} toggle={toggleGuideline} />
                </li>
            </ul>
            {isOpenImageDesign && <DesignLibraryModal isOpen={isOpenImageDesign} toggle={_toggle} />}
        </>
    )
}

export default Toolbar