import React, {Fragment} from 'react'
import {CompactPicker} from 'react-color'
import {UncontrolledPopover, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as FitToWidth} from '../../../assets/icons/arrow-h.svg'
import {ReactComponent as CropIcon} from '../../../assets/icons/crop.svg'
import {ReactComponent as FitToHeight} from '../../../assets/icons/arrow-v.svg'
import {ReactComponent as Duplicate} from '../../../assets/icons/duplicate.svg'
import {ReactComponent as HorizontalFlip} from '../../../assets/icons/horizontal-flip.svg'
import {ReactComponent as Trash} from '../../../assets/icons/trash-alt.svg'
import {ReactComponent as VerticalFlip} from '../../../assets/icons/vertical-flip.svg'
import {ReactComponent as RemoveBackground} from '../../../assets/icons/background.svg'
import {ReactComponent as PatternIcon} from '../../../assets/icons/pattern.svg'
import {useEditorBackground} from '../../../hooks/useEditorBackground'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {actionDuplicateLayer, removeLayer, updateLayerAttribute} from '../context/SelectedVariantContext/action'
import {CMYK_COLORS} from '../context/SelectedVariantContext/constants'
import {turnCmykToHex, turnHexToCmyk} from '../context/SelectedVariantContext/helper'
import classNames from 'classnames'
import LayerFontSelect from '../upload/tabs/campaign-design/LayerFontSelect'
import useFont from '../../../hooks/useFont'

const MockupEditorToolbar = () => {
    const {
        dispatch,
        state: {selectedLayer},
    } = useSelectedVariantContext()

    // const layers = useEditorLayers()
    const background = useEditorBackground()

    const onChangeTextAlign = (align) => {
        if (!selectedLayer || selectedLayer?.isLock) return
        selectedLayer.align = align
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const onChangeTextColor = (color) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        let selectedColor = '#000000'
        if (color.hex !== '#000000') {
            // convert hex từ color picker sang cmyk để giảm mã màu
            const hexTocmyk = turnHexToCmyk(color.hex)

            // sau đó đổi lại từ cmyk sang hex để set vào layer (vì layer không nhận mã màu cmyk)
            selectedColor = turnCmykToHex(hexTocmyk)
        }
        selectedLayer.fill = selectedColor
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const onFlip = (flipAxis) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                scaleX: flipAxis === 'x' ? -1 * selectedLayer.scaleX : selectedLayer.scaleX,
                scaleY: flipAxis === 'y' ? -1 * selectedLayer.scaleY : selectedLayer.scaleY,
            }),
        )
    }

    const onFullAxis = (axis) => {
        if (!selectedLayer || selectedLayer.isLock) return

        const {x: bgX, y: bgY, width: bgWidth, height: bgHeight} = background
        const {width: selectedWidth, height: selectedHeight, scaleX, scaleY} = selectedLayer

        const newAttribute = {...selectedLayer}
        const scaleBg = axis === 'x' ? bgWidth / selectedWidth : bgHeight / selectedHeight

        if (axis === 'x') {
            newAttribute.x = bgX + bgWidth / 2
            newAttribute.y = bgY + bgHeight / 2
        } else {
            newAttribute.x = bgX + bgWidth / 2
            newAttribute.y = bgY + bgHeight / 2
        }

        newAttribute.scaleX = scaleX < 0 ? scaleBg * -1 : scaleBg
        newAttribute.scaleY = scaleY < 0 ? scaleBg * -1 : scaleBg

        dispatch(updateLayerAttribute(newAttribute))
    }

    /**
     * Handle duplicate layer for current stage side
     */
    const handleDuplicate = () => {
        if (!selectedLayer) return
        // if (selectedLayer.layerType === 'image') {
        //     const exceedsLimit = checkImageUploadLimit(layers)
        //     if (exceedsLimit)
        //         return toaster({
        //             type: 'error',
        //             message: '5 images are allowed to upload',
        //         })
        // }
        dispatch(actionDuplicateLayer(selectedLayer))
    }

    const handleDuplicateAllArea = () => {
        if (!selectedLayer) return
        dispatch(actionDuplicateLayer(selectedLayer, {scopeAll: true}))
    }

    const handleRemoveLayer = () => {
        if (!selectedLayer || selectedLayer?.isLock) return

        dispatch(removeLayer(selectedLayer))
    }

    const {changeFont, fonts} = useFont()

    const updateFontSelectedTextLayer = changeFont(selectedLayer)

    const handleTogglePattern = () => {
        const {hasPattern} = selectedLayer
        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                hasPattern: !hasPattern,
            }),
        )
    }

    return (
        <Fragment>
            <div className="MockupEditorToolbar ImageToolbar" id="image-toolbar">
                <HorizontalFlip
                    className="mx-2"
                    color="default"
                    onClick={() => onFlip('x')}
                    id="flipX"
                    role={'button'}
                />

                <VerticalFlip className="mx-2" color="default" onClick={() => onFlip('y')} id="flipY" role={'button'} />
                <div className={'vr mx-2'}></div>
                <FitToHeight
                    className="mx-2"
                    color="default"
                    id="fitHeight"
                    onClick={() => onFullAxis('y')}
                    role={'button'}
                />
                <FitToWidth
                    className="mx-2"
                    color="default"
                    id="fitWidth"
                    onClick={() => onFullAxis('x')}
                    role={'button'}
                />
                <div className={'vr mx-2'}></div>
                <RemoveBackground
                    width={18}
                    height={18}
                    id={'removeBackground'}
                    className="mx-2"
                    color="default"
                    role={'button'}
                    onClick={() => {
                        //TODO: handle remove background
                    }}
                />
                <PatternIcon
                    width={18}
                    height={18}
                    id={'pattern'}
                    className="mx-2"
                    color="default"
                    role={'button'}
                    onClick={handleTogglePattern}
                />
                <CropIcon
                    id={'crop'}
                    className="mx-2"
                    color="default"
                    role={'button'}
                    onClick={() => {
                        //TODO: handle crop image
                    }}
                />
                <Duplicate
                    className="mx-2"
                    id={`image_duplicate`}
                    color="default"
                    onClick={handleDuplicate}
                    role={'button'}
                />
                <Trash
                    width={20}
                    height={20}
                    className="mx-2"
                    id={`image_remove`}
                    color="default"
                    onClick={handleRemoveLayer}
                    role={'button'}
                />
                <div className={'vr mx-2'}></div>
                <div
                    role={'button'}
                    className="mx-2"
                    id={`image_duplicateAll`}
                    color="default"
                    onClick={handleDuplicateAllArea}
                >
                    Duplicate all sides
                </div>
            </div>
            <div className="MockupEditorToolbar TextToolbar" id="text-toolbar">
                {fonts && (
                    <LayerFontSelect
                        fonts={fonts}
                        itemData={selectedLayer || {}}
                        // fontRandomId={fontRandomId}
                        handleChangeFont={updateFontSelectedTextLayer}
                    />
                )}
                <i
                    id="alignLeft"
                    className={classNames('mx-2 fa fa-align-left', {
                        active: selectedLayer && selectedLayer.align === 'left',
                    })}
                    onClick={() => onChangeTextAlign('left')}
                />
                <i
                    id="alignCenter"
                    className={classNames('mx-2 fa fa-align-center', {
                        active: selectedLayer && selectedLayer.align === 'center',
                    })}
                    onClick={() => onChangeTextAlign('center')}
                />

                <i
                    id="alignRight"
                    className={classNames(`mx-2 fa fa-align-right`, {
                        active: selectedLayer && selectedLayer.align === 'right',
                    })}
                    onClick={() => onChangeTextAlign('right')}
                />
                <div className={'vr mx-2'}></div>
                <div className="mx-2" color="default" id="TextColorSelector" role={'button'}>
                    <i className="fa fa-font"></i>
                    <div className="TextColor" style={{backgroundColor: `black`}}></div>
                </div>
                <Duplicate
                    className="mx-2"
                    id={`text_duplicate`}
                    color="default"
                    onClick={handleDuplicate}
                    role={'button'}
                />
                <Trash
                    width={20}
                    height={20}
                    className="mx-2"
                    id={`text_remove`}
                    color="default"
                    onClick={handleRemoveLayer}
                    role={'button'}
                />
                <div className={'vr mx-2'}></div>
                <div
                    className="mx-2"
                    id={`text_duplicateAll`}
                    color="default"
                    onClick={handleDuplicateAllArea}
                    role={'button'}
                >
                    Duplicate all sides
                </div>

                <UncontrolledPopover trigger="legacy" placement="top" target="TextColorSelector">
                    <CompactPicker
                        color={selectedLayer?.fill || '#ffffff'}
                        colors={CMYK_COLORS}
                        onChange={onChangeTextColor}
                    />
                </UncontrolledPopover>
            </div>
            <ButtonTooltip />
        </Fragment>
    )
}

const ButtonTooltip = () => {
    return (
        <Fragment>
            <UncontrolledTooltip placement="top" target="flipX">
                Horizontal flip
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="flipY">
                Vertical flip
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="fitHeight">
                Fit to height
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="fitWidth">
                Fit to width
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="removeBackground">
                Remove background
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="pattern">
                Pattern
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="crop">
                Crop
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="TextColorSelector">
                Change text color
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="alignLeft">
                Align left
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="alignCenter">
                Align center
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="alignRight">
                Align right
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="image_duplicateAll">
                Duplicate all sides
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="image_duplicate">
                Duplicate
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="image_remove">
                Remove
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="text_duplicateAll">
                Duplicate all sides
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="text_duplicate">
                Duplicate
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="text_remove">
                Remove
            </UncontrolledTooltip>
        </Fragment>
    )
}

export default MockupEditorToolbar
