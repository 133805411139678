import React, {useCallback, useEffect, useState} from 'react'
import Slider from 'react-slick'
import {ReactComponent as ChevronLeft} from '../../../../assets/icons/chevron-left.svg'
import {ReactComponent as ChevronRight} from '../../../../assets/icons/chevron-right.svg'
import {ReactComponent as Reload} from '../../../../assets/icons/renew.svg'
import {usePreviewArtworks} from '../../../../hooks/usePreviewArtworks'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {isSubsetAttributes} from '../../context/SelectedVariantContext/helper'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CampaignDesignLoading from '../../upload/tabs/campaign-design/CampaignDesignLoading'
import {Spinner} from 'reactstrap'
import PreviewBackgroundImage from './PreviewBackgroundImage'

const PreviewArtworkContainer = () => {
    const {fetchPreviews, percentage, pending} = usePreviewArtworks()
    const {
        state: {selectedAttribute, previewImages, reloadPreview, reloadBgPreview},
    } = useSelectedVariantContext()
    const [wPreview, setWPreview] = useState(1000)
    const [hPreview, setHPreview] = useState(800)

    const [previewSrc, setPreviewSrc] = useState([])
    const [firstLoad, setFirstLoad] = useState(true)

    const loadPreviewImages = useCallback(() => {
        if (!previewImages.length || !selectedAttribute) return []
        setOnImgError(false)
        setFirstLoad(false)

        const selectedAttr = selectedAttribute.attributes.map((attr) => {
            return {type: attr.type, value: attr.value_code}
        })

        const images =
            previewImages.find((preview) => isSubsetAttributes(preview.attributes, selectedAttr))?.images || []
        setPreviewSrc(images)
    }, [previewImages, selectedAttribute])

    const getInfoPreviewArtworkSlider = () => {
        let w = 0
        let h = 0
        if (document.querySelector('.PreviewArtworkContainer')) {
            w = window.innerWidth
            if (document.querySelector('#left-sidebar')) {
                w = window.innerWidth - document.querySelector('#left-sidebar').offsetWidth
            }
            if (document.querySelector('#right-sidebar')) {
                w = window.innerWidth - document.querySelector('#right-sidebar').offsetWidth
            }
            h = document.querySelector('.PreviewArtworkContainer').offsetHeight
        }
        setWPreview(w)
        setHPreview(h)
    }

    useEffect(() => {
        loadPreviewImages()
    }, [loadPreviewImages])

    useEffect(() => {
        getInfoPreviewArtworkSlider()
        fetchPreviews()
        window.addEventListener('resize', getInfoPreviewArtworkSlider)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [onImgError, setOnImgError] = useState(false)

    const loading = reloadPreview || (pending && !onImgError)

    return (
        <div className={`PreviewArtworkContainer d-flex`} style={{width:'100%'}}>
            <div className="PreviewArtworkSlider" style={{width: wPreview}}>
                {percentage !== 0 && loading && (
                    <div className="LoadingContainer d-flex align-items-center justify-content-center" style={{height: hPreview}}>
                        <CampaignDesignLoading
                            percentage={percentage}
                            text="Your mockup is generating, please wait..."
                        />
                    </div>
                )}
                <div className={loading ? 'd-none' : 'd-block'}>
                    {onImgError ? (
                        <div className="PreviewArtworkErrorWrapper text-center">
                            <div className="PreviewArtworkError">
                                <div>
                                    <p className="text-danger mb-0">Something went wrong, please try again.</p>
                                    <Reload width={20} height={20} onClick={loadPreviewImages} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Slider fade dots draggable={false} nextArrow={<ChevronRight />} prevArrow={<ChevronLeft />}>
                            {previewSrc.map((src, index) => (
                                <div key={index}>
                                    <div className='Image' style={{height: hPreview}}>
                                        {!firstLoad && (
                                            <div className='ImageFirstLoading text-center'>
                                                <Spinner style={{color: '#007bff'}} />
                                            </div>
                                        )}
                                        <PreviewBackgroundImage
                                            index={index}
                                            imageProp={`${src}?timestamp=${new Date().getTime()}`}
                                            reloadBgPreview={reloadBgPreview}
                                            wPreview={wPreview}
                                            hPreview={hPreview}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PreviewArtworkContainer

