import Bluebird from "bluebird"
import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import { ReactComponent as ChevronLeft } from '../../../../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg'
import { useEditorStageSides } from "../../../../hooks/useEditorStageSides"
import { usePreviewArtworks } from "../../../../hooks/usePreviewArtworks"
import { useSelectedVariantContext } from "../../context/SelectedVariantContext"
import { loadImage } from "../../context/SelectedVariantContext/helper"
import { getBabylonInstance } from "./core/Babylon"
import cutDesignParts from "./core/cutDesignParts"

const PreviewLiveArtworkContainer = () => {
    const {state: { product, designs, selectedSide }} = useSelectedVariantContext()
    const {fetchPreview3D} = usePreviewArtworks()
    const stageSides = useEditorStageSides()

    const {
        preview_v2: mockupPreviewV2Config,
        preview_v3: mockupPreviewV3Config,
    } = product.mockups[0]
    const {mockup_views: mockupViews = []} = mockupPreviewV3Config

    const renderCanvasRef = useRef(null)

    const [previewMode, setPreviewMode] = useState('mockup')
    const [selectedMockupView, setSelectedMockupView] = useState(mockupViews.length ? 0 : null)

    const switchPreview = (mode) => {
        if (mode === previewMode) return
        setPreviewMode(mode)
    }

    const start3D = async () => {
        const babylon = getBabylonInstance(
            renderCanvasRef.current,
            mockupPreviewV3Config.uv_map_configs
        )

        if (mockupViews[selectedMockupView]) {
            babylon.attachMockupCamera('mockup_canvas', {
                horizontalRotation: mockupViews[selectedMockupView].horizontal_rotation,
                verticalRotation: mockupViews[selectedMockupView].vertical_rotation,
                radius: mockupViews[selectedMockupView].radius,
            })
        }
        
        babylon.loadMaterialTexture(mockupPreviewV3Config.material_url)
        await babylon.loadModel(mockupPreviewV3Config.model_url)
        babylon.updateDesignTexture()
        
        babylon.run()
    }

    const updateUVMapping = async () => {
        const templateLayer = stageSides[0]?.find((stageSide) => stageSide.side === selectedSide).background
        const templateConfigs = mockupPreviewV2Config[selectedSide]
        const cuttingSideConfigs = mockupPreviewV3Config.cutting_mask_configs[selectedSide]?.parts

        if (!templateLayer || !templateConfigs || !cuttingSideConfigs) return

        await Bluebird.map(cuttingSideConfigs, async (config) => {
            config.image = await loadImage(config.url)
        }, {concurrency: 3})

        const preview3D = await fetchPreview3D()
        const sideArtwork = preview3D[0].artworks.find(e => e.side === selectedSide)

        if (!sideArtwork.src) return

        const designParts = await cutDesignParts(
            await loadImage(sideArtwork.src),
            templateLayer,
            templateConfigs,
            cuttingSideConfigs,
            mockupPreviewV3Config.scale,
        )

        const babylon = getBabylonInstance()
        designParts.forEach((part, index) => {
            babylon.drawUV(part.image, selectedSide, index)
        })
    }

    const switchMockupView = (index) => {
        setSelectedMockupView(index)
        const mockupView = mockupViews[index]
        const babylon = getBabylonInstance()
        babylon.updateMockupCameraPosition(mockupView.horizontal_rotation, mockupView.vertical_rotation, mockupView.radius)
    }

    useEffect(() => {
        start3D()

        return () => {
            const babylon = getBabylonInstance()
            babylon.destroy()
        }
    }, [])

    useEffect(() => {
        updateUVMapping()
    }, [designs])

    return (
        <div id="preview_live_artwork_container" className="PreviewLiveArtworkContainer d-flex flex-column w-100">
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    onClick={() => switchPreview('mockup')}
                    className={classNames('Preview2DButton', {active: previewMode === 'mockup'})}
                    disabled={!mockupViews.length}
                >
                    Mockup
                </button>
                <button
                    type="button"
                    onClick={() => switchPreview('3d')}
                    className={classNames('Preview3DButton', {active: previewMode === '3d'})}
                >
                    3D
                </button>
            </div>
            {mockupViews.length && (
                <div className="PreviewLiveArtworkCanvas" style={{display: previewMode === 'mockup' ? 'block' : 'none'} }>
                    <div className="d-flex flex-column h-100">
                        <div className="position-relative" style={{flexGrow: 1}}>
                            <canvas id="mockup_canvas" className="position-absolute w-100 h-100" />
                        </div>
                        <div style={{padding: '30px 12px'}}>
                            <Slider
                                infinite
                                slidesToShow={3}
                                slidesToScroll={1}
                                draggable={false}
                                nextArrow={<ChevronRight />}
                                prevArrow={<ChevronLeft />}
                            >
                                {mockupViews.map((view, index) => (
                                    <div key={index}>
                                        <div
                                            className={classNames('SwitchMockupViewButton', {active: selectedMockupView === index})}
                                            onClick={() => switchMockupView(index)}
                                        >
                                            <img src={view.preview_url} alt={index} className="w-100 h-100" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            )}
            <div className="PreviewLiveArtworkCanvas position-relative" style={{display: previewMode === '3d' ? 'block' : 'none'}}>
                <canvas ref={renderCanvasRef} className="position-absolute w-100 h-100" />
            </div>
        </div>
    )
}

export default PreviewLiveArtworkContainer
